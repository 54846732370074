import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import Locales from '../definitions/Locales';
import Formats from '../definitions/Formats';


function IntlProvider({ locale, messages, children, ...otherProps }) {
    return (
        <ReactIntlProvider
            locale={locale}
            messages={messages}
            formats={Formats}

            defaultLocale={Locales.ENGLISH}
            defaultFormats={Formats}

            wrapRichTextChunksInFragment={true}
            {...otherProps}
        >
            {children}
        </ReactIntlProvider>
    );
}

IntlProvider.propTypes = {
    locale: PropTypes.oneOf([
        Locales.ENGLISH,
        Locales.FRENCH,
        Locales.PSEUDO,
    ]),
    messages: PropTypes.object.isRequired,
    children: PropTypes.any.isRequired,
};

IntlProvider.defaultProps = {
    locale: undefined,
};

export default IntlProvider;
