import { Locales, withIntlProvider } from '@ratehub/base-ui';

function withEnglishLocale(Component) {
  return withIntlProvider({
    Component: Component,
    locale: Locales.ENGLISH,
    messages: {}
  });
}

export default withEnglishLocale;