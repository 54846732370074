import React from 'react';
import hoistStatics from 'hoist-non-react-statics';

import IntlProvider from '../components/IntlProvider';
import ModalProvider from '../components/ModalProvider';
import getComponentDisplayName from './getComponentDisplayName';


function withIntlProvider({ Component, locale, messages }) {
    function WrappedComponent(props) {
        return (
            <IntlProvider
                locale={locale}
                messages={messages}
            >
                {/*
                  * Note: The reasoning behind ModalProvider being placed here rather than _app.jsx is that it uses IntlProvider.
                  * ModalProvider needs to be lower in the React tree than IntlProvider to consume the `intl` object.
                */}
                <ModalProvider>
                    <Component
                        {...props}
                    />
                </ModalProvider>
            </IntlProvider>
        );
    }

    // Hoisting statics is critical to allow Next.js to work with wrapped pages
    // (getInitialProps, getStaticProps, getStaticPaths)
    hoistStatics(WrappedComponent, Component);

    WrappedComponent.displayName = `withIntlProvider(${getComponentDisplayName(Component)})`;

    return WrappedComponent;
}

export default withIntlProvider;
